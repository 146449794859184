import React from 'react';
import Link from 'gatsby-link';
import Fingerprint from "fingerprintjs";

import Helmet from 'react-helmet';
import Layout from "../layouts";

function findIP(onNewIP) {
	const myPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;

	if (!myPeerConnection) return;

	let pc = new myPeerConnection({iceServers: [{urls: "stun:stun.l.google.com:19302"}]}),
		noop = function () {
		},
		localIPs = {},
		ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;

	function ipIterate(ip) {
		if (!localIPs[ip]) onNewIP(ip);
		localIPs[ip] = true;
	}

	pc.createDataChannel("");

	pc.createOffer(function (sdp) {
		sdp.sdp.split('\n').forEach(function (line) {
			if (line.indexOf('candidate') < 0) return;
			line.match(ipRegex).forEach(ipIterate);
		});
		pc.setLocalDescription(sdp, noop, noop);
	}, noop);

	pc.onicecandidate = function (ice) {
		if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
		ice.candidate.candidate.match(ipRegex).forEach(ipIterate);
	};
}

class LeakTest extends React.Component {
	state = {
		webRtcTested            : false,
		webRtcLocal             : null,
		webRtcPublic            : null,
		webRtcIpv6              : null,
		browserFingerprint      : null,
		browserFingerprintCanvas: null,
		browserFingerprintLoaded: false,

		ipinfo: {
			ip           : "loading...",
			country_short: "loading...",
			region       : "loading...",
			city         : "loading...",
			latitude     : "loading...",
			longitude    : "loading...",
			postal_code  : "loading..."
		}
	};

	componentDidMount() {
		const self = this;

		//insert IP addresses into the page
		findIP((ip) => {
			if (ip.match(/^(192\.168\.|169\.254\.|10\.|172\.(1[6-9]|2\d|3[01]))/)) {
				self.setState({webRtcLocal: ip, webRtcTested: true});
			} else if (ip.match(/^[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7}$/)) {
				self.setState({webRtcIpv6: ip, webRtcTested: true});
			} else {
				self.setState({webRtcPublic: ip, webRtcTested: true});
			}
		});

		fetch("https://api.infoip.io")
			.then(res => res.json())
			.then((result) => {
				self.setState({
					ipinfo: result
				})
			});

		self.setState({
			browserFingerprint      : new Fingerprint().get(),
			browserFingerprintCanvas: new Fingerprint({canvas: true}).get(),
			browserFingerprintLoaded: true
		});

		setTimeout(() => {
			self.setState({webRtcTested: true});
		}, 3000)
	}

	render() {
		const self = this;
		return (
			<Layout>
				<Helmet title={"Ip leak test & fingerprinting"}/>
				<section className="jumbotron">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<h1>Ip leak test &amp; fingerprinting<br/>
									<span>{this.state.ipinfo.ip}</span>
								</h1>
							</div>
						</div>

						<section className="list-of-properties">
							<div className="container">
								<div className="row">
									<div className="col-md-6 col-xs-6 list-left">
										<ul className="properties-list">
											<li>Country</li>
											<li>Region</li>
											<li>City</li>
											<li>Latitude/Longitude</li>
											<li>Postal Code</li>
											<li>WebRTC Local</li>
											<li>WebRTC IpV6</li>
											<li>WebRTC Public</li>
											<li>Browser fingerprint</li>
											<li>Browser fingerprint (canvas)</li>
										</ul>
									</div>
									<div className="col-md-6 col-xs-6 list-right">
										<ul className="properties-list">
											<li>{this.state.ipinfo.country_short}</li>
											<li>{this.state.ipinfo.region}</li>
											<li>{this.state.ipinfo.city}</li>
											<li>{this.state.ipinfo.latitude}/{this.state.ipinfo.longitude}</li>
											<li>{this.state.ipinfo.postal_code}</li>
											<li>
												{this.state.webRtcTested ? this.state.webRtcLocal ||
													<i>Unknown</i> : "Loading..."}
											</li>
											<li>
												{this.state.webRtcTested ? this.state.webRtcIpv6 ||
													<i>Unknown</i> : "Loading..."}
											</li>
											<li>
												{this.state.webRtcTested ? this.state.webRtcPublic ||
													<i>Unknown</i> : "Loading..."}
											</li>
											<li>
												{this.state.browserFingerprintLoaded ? this.state.browserFingerprint || "--" : "Loading..."}
											</li>
											<li>
												{this.state.browserFingerprintLoaded ? this.state.browserFingerprintCanvas || "--" : "Loading..."}
											</li>
										</ul>
									</div>
								</div>
							</div>
						</section>
						<br/>
						<br/>

						<div className="row">
							<div className="col-md-12">
								<Link to="/pricing" className="btn btn-first">
									API pricing &amp; docs
								</Link>
							</div>
						</div>
					</div>
				</section>

				<section className="asks">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<h2>Ip leak test &amp; fingerprinting FAQ</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className="ask-item">
									<h3 className="qustion">What is an ip leak?</h3>
									<div className="answer">
										<p>
											Certain browsers and browser configurations may accidentally leak your
											real ip address bypassing the proxy or VPN software. That behavior is
											usually defined as an ip leak.
										</p>
									</div>
								</div>
								<div className="ask-item">
									<h3 className="qustion">What is a browser fingerprint?</h3>
									<div className="answer">
										<p>
											Each browser has a unique fingerprint that is calculated using various
											techniques. In most cases, using a VPN or a proxy is not enough as you
											can still be identified by this fingerprint. Fingeprinting is done by
											checking the enabled modules, browser info, cpu info and other things. In
											most cases, running in incognito wil not change your fingeprint.
										</p>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="ask-item">
									<h3 className="qustion">What is WebRTC?</h3>
									<div className="answer">
										<p>
											WebRTC is a implementation that provides Real-Time Communications (RTC).
											When this feature is enabled in your browser (it usually is), it can leak
											your real ip address and other information.
										</p>
									</div>
								</div>
								<div className="ask-item">
									<h3 className="qustion">How do I stay completely anonymous?</h3>
									<div className="answer">
										<p>
											This is a real challenge but we can try: disable Java, disable WebRTC,
											disable Flash/ActiveX and make sure your browser has different
											fingeprints (hardest part).
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Layout>
		)
	}
}

export default LeakTest
